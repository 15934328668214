<template>
  <div class="column">
    <q-table flat wrap-cells
      :data="instances"
      :columns="columns"
      row-key="id"
      :rows-per-page-options="rowsPerPageOptions"
      :pagination.sync="pagination"
      :loading="loading"
      :filter="filter"
      @request="onRequest"
      binary-state-sort>
      <q-tr slot="body" slot-scope="props" :props="props"
        @click.native="$router.push({ name: 'instance-show', params: { id: props.row.id } })"
        class="cursor-pointer">
        <q-td v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
          <div v-if="col.name === 'statut' && col.value" v-html="$t(`instances.fields.statut.options.${col.value}`)" />
          <div v-else-if="col.value" v-html="col.value" />
        </q-td>
      </q-tr>
    </q-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'InstancesList',

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (!store.getters['instances/getFilters']) {
      store.dispatch('instances/fetchFilters')
    }
  },

  data () {
    return {
      filter: '',
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      instances: 'instances/getInstances',
      filters: 'instances/getFilters',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),

    pagination: {
      ...mapGetters({ get: 'instances/getPagination' }),
      ...mapMutations({ set: 'instances/setPagination' })
    },

    columns () {
      if (this.instances.length === 0 || !this.filters) return []
      else {
        const region_filter = this.filters.find(filter => filter.id === 'region_id')
        const fields = region_filter
          ? ['date_instance', 'region_id', 'sujets_count', 'label', 'statut']
          : ['date_instance', 'sujets_count', 'label', 'statut']

        const sortable = []
        return fields.map(key => {
          let label = this.$t(`instances.fields.${key}.label`)
          let align = 'left'
          let autoWidth = false
          let formatFn = val => val

          if (key === 'date_instance') {
            autoWidth = true
            formatFn = val => this.$formatDate(val)
          } else if (key === 'region_id') {
            formatFn = val => {
              const option = region_filter.options.find(option => option.value === val)
              return this.$t(`instances.fields.region_id.options.${option.label}`)
            }
          // } else if (key === 'auteur' || key === 'destinataire') {
          //   formatFn = val => val['display_name']
          // } else if (key === 'statut' || key === 'qualification') {
          //   align = 'center'
          //   autoWidth = true
          // } else if (key === 'model') {
          //   formatFn = val => {
          //     if (val && val['NOMITV']) { return val['TITULAIRE'] }
          //     if (val && val['NOMPTF']) { return val['TITULAIRE'] }
          //     if (val && val['CODEICX']) { return val['label'] }
          //     if (val && val['groupe_cible']) { return val['type'] }
          //     return val && val.label
          //   }
          }

          return {
            name: key,
            align: align,
            label: label,
            field: key,
            format: formatFn,
            sortable: sortable.indexOf(key) >= 0,
            autoWidth: autoWidth
          }
        })
      }
    }
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        this.$nextTick(() => {
          this.onRequest({ pagination: this.pagination })
        })
      },
      deep: true
    }
  },

  mounted () {
    this.$store.commit('pages/setPageTitle', this.$t('instances.instances_list'))

    if (this.filters) { this.onRequest({ pagination: this.pagination }) }
  },

  methods: {
    onRequest ({ pagination, filter }) {
      this.$store.commit('instances/setPagination', pagination)

      let params = { ...pagination, statut: this.statut }
      this.filters.forEach(filter => { params[filter.id] = filter.value })
      if (this.$route.name === 'compte-rendus-list') params.compte_rendu = true

      this.loading = true
      this.$store.dispatch('instances/fetchInstances', params)
        .finally(() => { this.loading = false })
    }
  }
}
</script>
